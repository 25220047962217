<script>
    import VueMultiSelect from 'vue-multiselect';

    export default {
        name: "MultiSelect",
        extends: VueMultiSelect,
        props: {
            placeholder: {
                default: 'Sélectionner une option'
            },
            selectLabel: {
                default: "Sélectionner avec Entrée"
            },
            deselectLabel: {
                default: "Désélectionner avec Entrée"
            },
            selectedLabel: {
                default: "Sélectionné"
            },
            clearOnSelect: {
                default: false
            },
            preserveSearch: {
                default: false
            },
            multiple: {
                default: false
            },
            label: {
                default: 'nom'
            }
        }
    }
</script>
